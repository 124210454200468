// src/firebaseService.ts
import { getDatabase, ref, set, get, update, remove, push, query, orderByChild, startAt, endAt, limitToFirst, limitToLast, equalTo, onValue } from "firebase/database";
import { database } from "@/firebaseConfig";
import {  getMD5 } from '@/utils/pagination';

// Crear (Create)
export const createEvent = async (event: any) => {
  console.log(event)
  const newEventRef = ref(database, 'Eventos/' + event.Id);
  await set(newEventRef, event);
};

// Leer (Read)
export const getEvents = async () => {
  const eventsRef = ref(database, 'Eventos');
  const snapshot = await get(eventsRef);
  return snapshot.exists() ? snapshot.val() : [];
};

export const getEventsByInstitucion = async (institucion: string) => {
   
  const eventsRef = ref(database, 'Eventos');  
  const eventQuery = query(eventsRef, orderByChild('Institucion'), equalTo(institucion));
  try {
  const snapshot = await get(eventQuery);     
  return snapshot.exists() ? snapshot.val() : [];
} catch (error) {
  console.error("Error al obtener los eventos por Institucion: ", error);
  throw error;
}
};

export const getEventsByInstitucionOnValue = async (institucion: string) => {
   
  const eventsRef = ref(database, 'Eventos');  
  const eventQuery = query(eventsRef, orderByChild('Institucion'), equalTo(institucion));
  let retorno: any = []; 
  try {
    onValue(eventQuery, (snapshot) => {
      retorno = snapshot.exists() ? snapshot.val() : [];      
    });
    return retorno;   
    } catch (error) {
  console.error("Error al obtener los eventos por Institucion: ", error);
  throw error;
    }
};



// Leer (Read)
export const getEventByID = async () => {
    const eventsRef = ref(database, 'Eventos');
    const snapshot = await get(eventsRef);
    return snapshot.exists() ? snapshot.val() : [];
  };

// Actualizar (Update)
export const updateEvent = async (eventId: number, event: any) => {
  const eventRef = ref(database, 'Eventos/' + eventId);
  await update(eventRef, event);
};

// Eliminar (Delete)
export const deleteEvent = async (eventId: number) => {
  const eventRef = ref(database, 'Eventos/' + eventId);
  await remove(eventRef);
};


  export const searchEventsByID = async (numeroEvento: string) => {
    const eventsRef = ref(database, 'Eventos');
    const eventQuery = query(eventsRef, orderByChild('Id'), equalTo(numeroEvento));
    const snapshot = await get(eventQuery);   
    
    if (snapshot.exists()) {
      const data = snapshot.val();
      // Retornar el primer elemento que coincida
      return Object.values(data)[0];
    } else {
      return null;
    }
  };

  export const addOrder = async (guid: string, data: Record<string, any>): Promise<void> => {
    try {       
      
      const nodeP: string = "Eventos/" + data.IndexKey; 
      const orderRef = ref(database, `${nodeP}/OrdenCompra/${guid}`);
      await set(orderRef, data);
      console.log('Orden de compra agregada exitosamente');
    } catch (error) {
      console.error('Error al agregar la orden de compra:', error);
    }
  };

  export const addRegistroLectura = async (guid: string, data: Record<string, any>): Promise<void> => {
    try {             
      const nodeP: string = "Eventos/" + data.IndexKey; 
      const orderRef = ref(database, `${nodeP}/RegistroEntrada/${guid}`);
      await set(orderRef, data);
      console.log('Orden de compra agregada exitosamente');
    } catch (error) {
      console.error('Error al agregar la orden de compra:', error);
    }
  };

  export const updateOrder = async (orderId: string, orderData:  Record<string, any>): Promise<void> => {
    try {
      const nodeP: string = "Eventos/" + orderData.IndexKey; 
      const orderRef = ref(database, `${nodeP}/OrdenCompra/${orderId}`);

         // Verificar si orderRef existe y contiene datos
         
         
         const snapshot = await get(orderRef);
         if (snapshot.exists()) {                      
            await update(orderRef, orderData);
            console.log('Orden de compra actualizada exitosamente');
         } else {
          console.log("No esxite Registro de Orden.."); 
         }

    } catch (error) {
      console.error('Error actualizando la orden de compra:', error);
    }
  }

  export const addFormulario = async (guid: string, data: Record<string, any>): Promise<void> => {
    try {
      const orderRef = ref(database, `Respuestas/${guid}`);
      await set(orderRef, data);
      console.log('Orden de Formualrio agregada exitosamente');
    } catch (error) {
      console.error('Error al agregar la orden de Formulario:', error);
    }
  };


export const fetchContratos = async (limit: number) => {
    const contratosRef = query(ref(database, 'Eventos'), limitToLast(limit));
    const snapshot = await get(contratosRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return {};
    }
  };

  export const startSesion = async (formulario: any) => {

    if(typeof(formulario) === "undefined"){
      return {IsLogin: false, user: null};
    }

    const userRef = ref(database, 'Usuarios');
    const userQuery = query(userRef, orderByChild('Correo'), equalTo(formulario.username));
    const snapshot = await get(userQuery);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const oob: any = Object.values(data)[0];

      console.log("Existe registro de Usuario...");
      if( oob.Clave == getMD5(formulario.password) ){
        console.log("Clave correcta");
        console.log(oob.Clave + ' == ' + getMD5(formulario.password));

        return {IsLogin: true, user: oob};      
      }

      return {IsLogin: false, user: null};  
    } else {
      return {IsLogin: false, user: null};
    }    
  }; 

  export const getOrdenCompraByEventsID = async (numeroEvento: string) => {
    const eventsRef = ref(database, 'Eventos');
    const eventQuery = query(eventsRef, orderByChild('Id'), equalTo(numeroEvento));
    const snapshot = await get(eventQuery);   
    
    if (snapshot.exists()) {
      const data = snapshot.val();
      // Retornar el primer elemento que coincida
      return Object.values(data)[0];
    } else {
      return null;
    }
  };
