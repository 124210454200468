<template>
  <div class="hello">
    <div class="container-fluid pt-5 pb-3">
        <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Eventos</span></h2>
        <div class="row px-xl-5">
            
            <div class="col-lg-3 col-md-4 col-sm-6 pb-1" v-for="(e, k) in events" :key="k">
                <router-link :to="{name: 'DetalleEvents', params: {id: e.Id }}" class="item-event">
                <div class="product-item bg-light mb-4">
                    <div class="product-img position-relative overflow-hidden">
                        <img class="img-fluid w-100" :src="e.ImagenAfiche" :alt="e.Nombre">
                        
                    </div>
                    <div class="text-center py-4">
                        <a class="h6 text-decoration-none text-truncate" href="">Simposio de Parejas</a>
                        <div class="d-flex align-items-center justify-content-center mt-2">
                            <h5>{{ formatCurrency(e.PrecioMax, e.Moneda) }}</h5><h6 class="text-muted ml-2"></h6>
                        </div>
                    <div class="d-flex align-items-center justify-content-center mt-2">
                          <span> <i class="fa fa-calendar"></i> {{ e.FechaEventoDesc}}  </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center mt-2">
                          <span> <i class="fa fa-map-marker"></i> {{ e.LugarDesc}}  </span>
                    </div>
                        <div class="d-flex align-items-center justify-content-center mb-1">
                            <small class="fa fa-star text-primary mr-1"></small>
                            <small class="fa fa-star text-primary mr-1"></small>
                            <small class="fa fa-star text-primary mr-1"></small>
                            <small class="fa fa-star text-primary mr-1"></small>
                            <small class="fa fa-star-half-alt text-primary mr-1"></small>
                            <small>(5)</small>
                        </div>
                    </div>
                </div>
            </router-link>
            </div>
            
            
        </div>
    </div>
    <!-- Products End -->
    
    
  </div>
</template>

<style  scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a.item-event {
    color: #3D464D;
    text-decoration: none;
}
</style>


<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    msg: String,
    events: [],
  }
})
export default class HelloWorld extends Vue {
  msg!: string
  events!: any[]

    formatCurrency(amount: number, currency: string) {
    return amount.toLocaleString('es-US', { style: 'currency', currency: currency });
    }
}
</script>